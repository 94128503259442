import { useHistory } from 'react-router-dom';
import { SEND_REPORT_URL } from '../../utils/constant';
import { clearStorage } from '../../utils/storage';
import ErrorTemplate from './ErrorTemplate';

interface IErrorPage {
  status: string;
  message: string;
  errorMessage?: string;
  buttonLabel: string;
}

const ErrorPage = (props: IErrorPage) => {
  const { buttonLabel } = props;
  const history = useHistory();
  const buttonOnClick = () => {
    switch (buttonLabel) {
      case 'Back to previous page':
        history.goBack();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        break;
      case 'Back to Home':
        history.push('/');
        setTimeout(() => {
          window.location.reload();
        }, 100);
        break;
      case 'Unauthorized':
        clearStorage();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        break;
      case 'Try Again':
        window.location.reload();
        break;
      default:
        break;
    }
  };

  const sendReport = () => {
    window.open(SEND_REPORT_URL, '_blank', 'noopener,noreferrer');
  };

  const frontErrorActions = [
    {
      color: 'primary',
      variant: 'outlined',
      label: buttonLabel,
      onClick: buttonOnClick
    },
    {
      color: 'primary',
      variant: 'contained',
      label: 'SEND REPORT',
      onClick: sendReport
    }
  ];

  return <ErrorTemplate {...props} actions={frontErrorActions} />;
};

export default ErrorPage;
