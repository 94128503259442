import styled from 'styled-components';
interface ElementProps {
  role: string;
  isModal?: boolean;
}

function checkUsersRole(role: string) {
  switch (role) {
    case 'REKAVA':
      return {
        backgroundColor: '#CDCED9',
        color: '#353C68'
      };
    case 'ADMIN':
      return {
        backgroundColor: '#FFF8CC',
        color: '#CCAF00'
      };
    case 'PRINCIPAL':
      return {
        backgroundColor: '#F4F2FD',
        color: '#8E7FEC'
      };
    case 'COMPOSER':
      return {
        backgroundColor: '#E3F3FF',
        color: '#118CFF'
      };
    case 'LOGGER':
      return {
        backgroundColor: '#EBFAF4',
        color: '#46CF91'
      };
    case 'CHECKER':
      return {
        backgroundColor: '#FEF1EC',
        color: '#F67650'
      };
  }
}

export const NavBarStyle = styled.div({
  // zIndex: 3,
  // position: "fixed",
  top: 0,
  backgroundColor: '#FFFFFF',
  width: 'calc(100vw - 32px)',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 16px'
});

export const LeftContent = styled.div({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '16px',
  width: '37%',
  '& > .EmptyContentInsideLeftContent': {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& > .imageInsideLeftContent': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& > .contentInsideLeftContent': {
    width: '100%',
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '3px',
    '& > div': {
      height: '100%'
    }
  }
});

export const ContentList = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
});

export const MainContent = styled.div({
  display: 'inline-flex',
  gap: '0.2rem',
  '& > p': {
    margin: 0,
    lineHeight: '1.5rem',
    letterSpacing: '0.44px',
    '&:nth-child(2)': {
      fontSize: '1.1125rem',
      fontWeight: 700
    }
  }
});

export const RightContent = styled.div({
  display: 'flex',
  gap: '1rem',
  marginLeft: 'auto',
  '& > svg, img': {
    cursor: 'pointer'
  },
  ' > img': {
    height: 35,
    width: 35,
    objectFit: 'scale-down',
    borderRadius: 50
  }
});

export const RoleWrapper = styled.div<ElementProps>(props => ({
  width: '5.563rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 20,
  backgroundColor: checkUsersRole(props.role)?.backgroundColor,
  fontSize: 12,
  fontFamily: 'Inter',
  fontWeight: 'bold',
  lineHeight: '15px',
  letterSpacing: 0.4,
  padding: props.isModal ? '0.5rem 0' : 0,
  color: checkUsersRole(props.role)?.color
}));

export const List = styled.li({
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  padding: '6px 1rem 6px',
  textDecoration: 'none',
  fontSize: '1rem',
  lineHeight: 1.5,
  verticalAlign: 'middle',
  cursor: 'pointer',
  '& > img': {
    marginRight: '0.5rem'
  },
  '&:hover': {
    backgroundColor: '#E3F3FF'
  }
});

export const NavBarLogger = styled.div({
  padding: '0px',
  margin: '0px'
});

export const NavBarLoggerUl = styled.ul({
  padding: 0,
  margin: 0,
  listDecoration: 'none',
  listStyleType: 'none',
  cursor: 'pointer',
  li: {
    display: 'inline',
    marginRight: '16px',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '16px',
    fontFamily: 'Inter',
    listDecoration: 'none',
    a: {
      textDecoration: 'none',
      color: '#353C68'
    },
    '&:hover': {
      paddingBottom: '6px',
      borderBottom: 'solid 3px #118CFF'
    }
  }
});

export const ContentSection = styled.div({
  display: 'flex',
  columnGap: '2.5rem',
  flexWrap: 'wrap'
});

export const ProgressWrapper = styled.div({
  width: '12rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 20,
  fontSize: 12,
  fontFamily: 'Inter',
  fontWeight: 'bold',
  lineHeight: '15px',
  letterSpacing: 0.4,
  padding: 0,
  color: '#B4B4BB'
});

export const UserAvatar = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',

  ' > img': {
    height: 35,
    width: 35,
    objectFit: 'scale-down',
    borderRadius: 50
  },

  '& div > svg': {
    cursor: 'pointer'
  },

  '& > .user': {
    fontSize: 12,
    fontFamily: 'Inter',
    color: 'gray',
    fontWeight: 600,
    cursor: 'default',
    '& > .name': {
      color: '#000',
      fontSize: 14
    }
  }
});

export const IconAvatar = {
  height: '20px',
  width: '20px',
  // objectFit: "scale-down",
  borderRadius: '50px',
  top: '22px',
  left: '1320px'
};

export const IconAvatar2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const CalculationName = styled.div({
  color: '#29333D',
  fontSize: '16px',
  fontWeight: 'bold' as const,
  '& input': {
    border: '0px',
    outline: '0px',
    height: '24px',
    color: '#29333D',
    fontSize: '16px',
    fontWeight: 'bold' as const,
    // not responsive friendly, update to better support different screen sizes
    width: '400px',
    maxLength: 60
  },
  '& input:focus': {
    outline: '2px solid #118CFF',
    borderRadius: '6px'
  },
  '& p': {
    marginBottom: '2.5px',
    marginTop: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '400px'
  }
});

export const ComposerActionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  ' > img': {
    height: 18,
    width: 14,
    objectFit: 'scale-down'
  },

  '& div > svg': {
    cursor: 'pointer'
  }
});

export const LoggerActionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  columnGap: '18px',
  ' > img': {
    height: 19,
    width: 19,
    objectFit: 'scale-down'
  },
  '& div > svg': {
    cursor: 'pointer'
  }
});
