interface TextInput {
  [key: string]: string | undefined;
}

interface MenuItemsI {
  dataTestid: string;
  title: string;
  content: Array<{
    title: string;
    dataTestid: string;
  }>;
}

interface MenuCalcItemsI {
  key: string;
  title: string;
  divider?: boolean;
  dataTestid: string;
}

interface menuInputFileItemsI {
  key: string;
  title: string;
  divider?: boolean;
  dataTestid?: string;
}

type UserRole = {
  [key: string]: string;
};

export interface ErrorDataI {
  status: string;
  message: string;
  errorMessage: string;
  buttonLabel: string;
}

export interface businessDrivesItemsI {
  key: string;
  title: string;
  divider?: boolean;
  dataTestid: string;
}

export const levelOptions = [
  { label: 'Heading 1', value: 'HEADING_1' },
  { label: 'Heading 2', value: 'HEADING_2' },
  { label: 'Heading 3', value: 'HEADING_3' },
  { label: 'Heading 4', value: 'HEADING_4' }
];

export const alignOptions = [
  { label: 'Right', value: 'right' },
  { label: 'Left', value: 'left' },
  { label: 'Center', value: 'center' }
];

export const typeInput: TextInput = {
  TEXT_INPUT: 'text',
  FORMULA: 'text',
  DATE_INPUT: 'date',
  NUMERICAL_INPUT: 'number'
};

export const TopBarMenuItems: MenuItemsI[] = [
  {
    title: 'File',
    dataTestid: 'menuInputFile',
    content: [
      { title: 'New Input File', dataTestid: 'subMenuNew' },
      { title: 'Load', dataTestid: 'subMenuLoad' },
      { title: 'Save', dataTestid: 'subMenuSave' },
      { title: 'Save As', dataTestid: 'subMenuSaveAs' },
      { title: 'Exit to Input File Manager', dataTestid: 'subMenuExit' }
      // { title: 'Share', dataTestid: 'subMenuShare' },
      // { title: 'Publish', dataTestid: 'subMenuPublish' },
    ]
  },
  {
    title: 'Edit',
    dataTestid: 'menuEdit',
    content: [
      { title: 'Clear All Inputs', dataTestid: 'subMenuClearAllInputs' },
      { title: 'Clear Inputs at Page', dataTestid: 'subMenuClearInputsAtPage' }
    ]
  }
  // {
  //   title: 'Report',
  //   dataTestid: 'menuReport',
  //   content: [
  //     { title: 'Print to Report', dataTestid: 'subMenuPrintToReport' },
  //     { title: 'Report Styler', dataTestid: 'subMenuReportStyler' },
  //     { title: 'See Reports', dataTestid: 'subMenuSeeReports' },
  //   ],
  // },
  // {
  //   title: 'Calculation',
  //   dataTestid: 'menuCalculation',
  //   content: [
  //     { title: 'Go to Composer view', dataTestid: 'subMenuGoToComposerView' },
  //   ],
  // },
  //   {
  //   title: 'View',
  //   dataTestid: 'menuView',
  //   content: [{ title: ' ', dataTestid: 'subMenuShowHideInputs' }],
  // },
];

export const PrintViewTopBarMenuItems: MenuItemsI[] = [
  {
    title: 'Report',
    dataTestid: 'menuReport',
    content: [
      { title: 'Open Other Report', dataTestid: 'subMenuOpenTheReport' },
      { title: 'Save', dataTestid: 'subMenuSave' },
      // { title: 'Share', dataTestid: 'subMenuShare' },
      // { title: 'Publish', dataTestid: 'subMenuPublish' },
      // { title: 'Comment', dataTestid: 'subMenuComment' },
      { title: 'Exit to Report', dataTestid: 'subMenuExitToReport' }
    ]
  }
];

export const menuCalcItems: MenuCalcItemsI[] = [
  {
    key: 'createCalculation',
    title: 'Create Calculation',
    dataTestid: 'btnCreateCalculation'
  },
  {
    key: 'addFolder',
    title: 'Add Folder',
    dataTestid: 'btnAddFolder'
  }
  // {
  //   key: 'uploadSpreadSheet',
  //   title: 'Upload Spreadsheet',
  //   dataTestid: 'btnUploadSpreadsheet',
  // },
];

export const menuCommunituItems: MenuCalcItemsI[] = [
  {
    key: 'addFolder',
    title: 'Add Folder',
    dataTestid: 'btnAddFolder'
  }
];

export const menuInputFileItems: menuInputFileItemsI[] = [
  {
    key: 'createInputFile',
    title: 'Create Input File',
    dataTestid: 'menuCreateInputFile'
  }
];

export const roleObj: UserRole = {
  REKAVA_ADMIN: 'REKAVA',
  PRINCIPAL: 'PRINCIPAL',
  COMPANY_ADMIN: 'COMPANY ADMIN',
  COMPOSER_ADMIN: 'ADMIN',
  COMPOSER: 'COMPOSER',
  LOGGER: 'LOGGER',
  CHECKER: 'CHECKER'
};

export const customRole: UserRole = {
  REKAVA_ADMIN: 'Super Admin',
  PRINCIPAL: 'User',
  COMPANY_ADMIN: 'Admin',
  COMPOSER_ADMIN: 'Admin',
  COMPOSER: 'User',
  LOGGER: 'User',
  CHECKER: 'User'
};

// START MENU

export const composerMainMenuList = [
  {
    name: 'file',
    label: 'File',
    dataTestid: 'menuFileComposer',
    content: [
      {
        name: 'newCalculation',
        label: 'New Calculation',
        type: 'normal',
        dataTestid: 'subMenuNewCalculation'
      },
      {
        name: 'save',
        label: 'Save',
        type: 'normal',
        dataTestid: 'subMenuSave'
      },
      {
        name: 'saveas',
        label: 'Save As',
        type: 'normal',
        dataTestid: 'subMenuSaveAs'
      },
      // {
      //   name: 'share',
      //   label: 'Share',
      //   type: 'normal',
      //   dataTestid: 'subMenuShare',
      // },
      // {
      //   name: 'publish',
      //   label: 'Publish',
      //   type: 'extend',
      //   dataTestid: 'subMenuPublish',
      //   content: [
      //     {
      //       name: 'publishEnterprise',
      //       label: 'Publish to Enterprise',
      //       type: 'normal',
      //       dataTestid: 'subMenuPublishToEnterprise',
      //     },
      //     {
      //       name: 'publishCommunity',
      //       label: 'Publish to Community',
      //       type: 'normal',
      //       dataTestid: 'subMenuPublishToCommunity',
      //     },
      //   ],
      // },
      {
        type: 'divider'
      },
      {
        name: 'exitCalculation',
        label: 'Exit Calculation',
        type: 'normal',
        dataTestid: 'subMenuExitCalculation'
      }
    ]
  },
  {
    name: 'view',
    label: 'View',
    dataTestid: 'menuViewComposer',
    content: [
      {
        name: 'components',
        label: 'Components',
        type: 'radio',
        dataTestid: 'chkComponent'
      },
      // VARIABLE NETWORK ( JANGAN DIHAPUS )
      // {
      //   name: 'variableNetwork',
      //   label: 'Variable Network',
      //   type: 'radio',
      //   dataTestid: 'chkVariableNetwork',
      // },
      {
        name: 'variableManager',
        label: 'Variable Manager',
        type: 'radio',
        dataTestid: 'chkVariableManager'
      },
      {
        name: 'symbol',
        label: 'Symbol',
        type: 'radio',
        dataTestid: 'chkSymbol'
      }
      // COMMENT ( JANGAN DIHAPUS )
      // {
      //   name: 'comments',
      //   label: 'Comments',
      //   type: 'radio',
      //   dataTestid: 'chkComment',
      // },
    ]
  }
  // STYLE ( JANGAN DIHAPUS )
  // {
  //   name: 'style',
  //   label: 'Style',
  //   dataTestid: 'menuStyleComposer',
  //   content: [
  //     {
  //       name: 'chooseStyle',
  //       label: 'Choose a Style',
  //       type: 'extend',
  //       dataTestid: 'subMenuChooseAStyle',
  //       content: [
  //         {
  //           name: 'stylish',
  //           label: 'Stylish',
  //           type: 'normal',
  //           dataTestid: 'subMenuStylish',
  //         },
  //         {
  //           name: 'formal',
  //           label: 'Formal',
  //           type: 'normal',
  //           dataTestid: 'subMenuFormal',
  //         },
  //         {
  //           name: 'modern',
  //           label: 'Modern',
  //           type: 'normal',
  //           dataTestid: 'subMenuModern',
  //         },
  //         {
  //           name: 'compact',
  //           label: 'Compact',
  //           type: 'normal',
  //           dataTestid: 'subMenuCompact',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'createStyle',
  //       label: 'Create a Style',
  //       type: 'normal',
  //       dataTestid: 'subMenuCreateAStyle',
  //     },
  //   ],
  // },
  // HELP ( JANGAN DIHAPUS )
  // {
  //   name: 'help',
  //   label: 'Help',
  //   dataTestid: 'menuHelpComposer',
  //   content: [
  //     {
  //       name: 'videoTutorial',
  //       label: 'Video Tutorial',
  //       type: 'normal',
  //       dataTestid: 'subMenuVideoTutorial',
  //     },
  //     {
  //       name: 'documentation',
  //       label: 'Documentation',
  //       type: 'normal',
  //       dataTestid: 'subMenuDocumentation',
  //     },
  //   ],
  // },
];

export const loggerMenu = [
  {
    name: 'file',
    label: 'File',
    content: [
      {
        name: 'newCalculation',
        label: 'New Calculation',
        type: 'normal'
      },
      {
        name: 'save',
        label: 'Save',
        type: 'normal'
      },
      {
        name: 'saveas',
        label: 'Save As',
        type: 'normal'
      },
      {
        name: 'share',
        label: 'Share',
        type: 'normal'
      },
      {
        name: 'publish',
        label: 'Publish',
        type: 'extend',
        content: [
          {
            name: 'publishEnterprise',
            label: 'Publish to Enterprise',
            type: 'normal'
          },
          {
            name: 'publishCommunity',
            label: 'Publish to Community',
            type: 'normal'
          }
        ]
      },
      {
        type: 'divider'
      },
      {
        name: 'exitCalculation',
        label: 'Exit Calculation',
        type: 'normal'
      }
    ]
  },
  {
    name: 'view',
    label: 'View',
    content: [
      {
        name: 'components',
        label: 'Components',
        type: 'radio'
      },
      {
        name: 'variableNetwork',
        label: 'Variable Network',
        type: 'radio'
      },
      {
        name: 'variableManager',
        label: 'Variable Manager',
        type: 'radio'
      },
      {
        name: 'comments',
        label: 'Comments',
        type: 'radio'
      },
      {
        type: 'divider'
      },
      {
        name: 'loggerPreview',
        label: 'Logger Preview',
        type: 'normal'
      }
    ]
  }
];

export const printMenu = [
  {
    name: 'file',
    label: 'File',
    content: [
      {
        name: 'newCalculation',
        label: 'New Calculation',
        type: 'normal'
      },
      {
        name: 'save',
        label: 'Save',
        type: 'normal'
      },
      {
        name: 'saveas',
        label: 'Save As',
        type: 'normal'
      },
      {
        name: 'share',
        label: 'Share',
        type: 'normal'
      },
      {
        name: 'publish',
        label: 'Publish',
        type: 'extend',
        content: [
          {
            name: 'publishEnterprise',
            label: 'Publish to Enterprise',
            type: 'normal'
          },
          {
            name: 'publishCommunity',
            label: 'Publish to Community',
            type: 'normal'
          }
        ]
      },
      {
        type: 'divider'
      },
      {
        name: 'exitCalculation',
        label: 'Exit Calculation',
        type: 'normal'
      }
    ]
  }
];

// END MENU

// START OF CONSTANT COMPOSER

export const contentTable = ['Column', 'Row', 'None'];

export const contentText = [
  // 'TITLE',
  'HEADING_1',
  'HEADING_2',
  'HEADING_3',
  'NORMAL'
];

export const contentImage = ['LEFT', 'CENTER', 'RIGHT'];

export const contentInput = [
  {
    type: 'TEXT_INPUT',
    label: 'Text'
  },
  {
    type: 'DROPDOWN_INPUT',
    label: 'Dropdown'
  },
  {
    type: 'RADIO_BUTTON_INPUT',
    label: 'Radio Button'
  },
  {
    type: 'NUMERICAL_INPUT',
    label: 'Numerical'
  },
  {
    type: 'DATE_INPUT',
    label: 'Date'
  }
  // {
  //   type: 'CURRENCY_INPUT',
  //   label: 'Currency'
  // }
];

export const contentField = [
  {
    type: 'TEXT',
    label: 'Text'
  },
  {
    type: 'NUMERICAL',
    label: 'Numerical'
  },
  {
    type: 'DROPDOWN',
    label: 'Dropdown'
  },
  {
    type: 'RADIO_BUTTON',
    label: 'Radio Button'
  },
  {
    type: 'DATE',
    label: 'Date'
  }
];
export const contentComponent = [
  {
    type: 'TEXT',
    label: 'Text',
    svg: 'text'
  },
  {
    type: 'INPUT',
    label: 'Input',
    svg: 'input'
  },
  {
    type: 'FIELD',
    label: 'Field',
    svg: 'field'
  },
  {
    type: 'FORMULA',
    label: 'Formula',
    svg: 'formula'
  },
  {
    type: 'TABLE',
    label: 'Table',
    svg: 'table'
  },
  {
    type: 'DIVIDER',
    label: 'Divider',
    svg: 'divider'
  },
  {
    type: 'IMAGE',
    label: 'Image',
    svg: 'image'
  },
  {
    type: 'LINK',
    label: 'Link',
    svg: 'link'
  }
];

export const propertiesList = [
  {
    type: 'TEXT_INPUT',
    menu: [
      {
        type: 'subHeader',
        label: 'INPUT',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            content: 'Text',
            id: 'input',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            mandatory: true,
            dataTestid: 'inputDescriptionProperties'
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components. Alphanumeric LowerCase only and No spaces',
            mandatory: false,
            disabled: true,
            dataTestid: 'inputVariableProperties'
          },
          {
            type: 'smallProperties',
            label: 'Notation',
            id: 'notation',
            tooltip:
              'A shorter representation of this variable. Typically 1-3 characters long',
            mandatory: true,
            dataTestid: 'inputNotationProperties'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'DROPDOWN_INPUT',
    menu: [
      {
        type: 'subHeader',
        label: 'INPUT',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            content: 'Dropdown',
            id: 'input',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components. Alphanumeric LowerCase only and No spaces',
            dataTestid: 'inputVariableProperties',
            mandatory: false,
            disabled: true
          },
          {
            type: 'smallProperties',
            label: 'Notation',
            id: 'notation',
            tooltip:
              'A shorter representation of this variable. Typically 1-3 characters long',
            dataTestid: 'inputNotationProperties',
            mandatory: true
          },
          {
            type: 'optionProperties',
            id: 'options',
            tooltip:
              'List all the options you want to be shown in this dropdown component',
            dataTestid: 'inputOptionProperties',
            mandatory: true
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'RADIO_BUTTON_INPUT',
    menu: [
      {
        type: 'subHeader',
        label: 'INPUT',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            content: 'Radio Button',
            id: 'input',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components',
            dataTestid: 'inputVariableProperties',
            mandatory: false,
            disabled: true
          },
          {
            type: 'smallProperties',
            label: 'Notation',
            id: 'notation',
            tooltip:
              'A shorter representation of this variable. Typically 1-3 characters long',
            dataTestid: 'inputNotationProperties',
            mandatory: true
          },
          {
            type: 'optionProperties',
            id: 'options',
            tooltip:
              'List all the options you want to be shown in this radio button component',
            dataTestid: 'inputOptionProperties',
            mandatory: true
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'NUMERICAL_INPUT',
    menu: [
      {
        type: 'subHeader',
        label: 'INPUT',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            content: 'Numerical',
            id: 'input',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components. Alphanumeric LowerCase only and No spaces',
            dataTestid: 'inputVariableProperties',
            mandatory: false,
            disabled: true
          },
          {
            type: 'smallProperties',
            label: 'Notation',
            id: 'notation',
            tooltip:
              'A shorter representation of this variable. Typically 1-3 characters long',
            dataTestid: 'inputNotationProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Unit',
            id: 'unit',
            tooltip: 'The unit of this numerical input',
            dataTestid: 'inputUnitProperties'
          }
        ]
      },
      //KPD-2012 HIDE WARNING ABOUT HARD, SOFT,MAX VALIDATION
      // {
      //   type: 'subHeaderToggle',
      //   label: 'VALIDATION',
      //   subMenu: [
      //     {
      //       type: 'smallProperties',
      //       label: 'Hard Min',
      //       isNumber: true,
      //       id: 'hardMin',
      //       tooltip: 'Any input less than this number will prevent the Logger from completing their Calculation',
      //       dataTestid: 'inputHardMinProperties',
      //     },
      //     {
      //       type: 'smallProperties',
      //       label: 'Soft Min',
      //       isNumber: true,
      //       id: 'softMin',
      //       tooltip: 'Any input less than this number will trigger a warning message to the Logger, but will still allow them to proceed to printing',
      //       dataTestid: 'inputSoftMinProperties',
      //     },
      //     {
      //       type: 'smallProperties',
      //       label: 'Soft Max',
      //       isNumber: true,
      //       id: 'softMax',
      //       tooltip: 'Any input greater than this number will trigger a warning message to the Logger, but will still allow them to proceed to printing',
      //       dataTestid: 'inputSoftMaxProperties',
      //     },
      //     {
      //       type: 'smallProperties',
      //       label: 'Hard Max',
      //       isNumber: true,
      //       id: 'hardMax',
      //       tooltip: 'Any input greater than this number will prevent the Logger from completing their Calculation',
      //       dataTestid: 'inputHardMaxProperties',
      //     },
      //   ],
      // },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'DATE_INPUT',
    menu: [
      {
        type: 'subHeader',
        label: 'INPUT',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            content: 'Date',
            id: 'input',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components. Alphanumeric LowerCase only and No spaces',
            dataTestid: 'inputVariableProperties',
            mandatory: true
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary',
          // },
        ]
      }
    ]
  },
  {
    type: 'CURRENCY_INPUT',
    menu: [
      {
        type: 'subHeader',
        label: 'INPUT',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            content: 'Currency',
            id: 'input',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components. Alphanumeric LowerCase only and No spaces',
            dataTestid: 'inputVariableProperties',
            mandatory: true
          },
          {
            type: 'typeProperties',
            label: 'Currency',
            id: 'currency',
            tooltip: 'Choose the currency of this input variable',
            dataTestid: 'dropdownCurrencyProperties'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'FORMULA',
    menu: [
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your calculated variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Variable',
            id: 'variable',
            tooltip:
              'This calculation is Variable-driven. The variable you write here will be used to call this input for further calculations in the Formula or Table components. Alphanumeric LowerCase only and No spaces',
            dataTestid: 'inputVariableProperties',
            mandatory: false,
            disabled: true
          },
          {
            type: 'smallProperties',
            label: 'Notation',
            id: 'notation',
            tooltip:
              'A shorter representation of this variable. Typically 1-3 characters long',
            dataTestid: 'inputNotationProperties',
            mandatory: true
          },
          {
            type: 'smallProperties',
            label: 'Unit',
            id: 'unit',
            tooltip: 'The unit of this numerical input',
            dataTestid: 'inputUnitProperties'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'FORMULA',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Formula',
            id: 'formula',
            tooltip:
              'Calculate variables with the widely-known spreadsheet syntax. Start with a “=”',
            dataTestid: 'inputFormulaProperties'
          },
          {
            type: 'smallProperties',
            label: 'Reference',
            id: 'reference',
            tooltip:
              'Insert the source of your formula e.g. BS 5400, AISC 360-16, SNI-1726-2019',
            dataTestid: 'inputReferenceProperties'
          },
          {
            type: 'formulaLatexProperties',
            label: 'Show LaTeX in log view'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'TABLE',
    menu: [
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your table here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'booleanProperties',
            label: 'Allow to add row in logger view',
            id: 'addRow'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'TEXT',
    menu: [
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Level',
            id: 'level',
            tooltip: 'Choose the Heading Level you want your text to be',
            dataTestid: 'dropdownLevelText'
          },
          {
            type: 'bigProperties',
            label: 'Text',
            id: 'text',
            tooltip: 'What you type here will be shown on the Workpage',
            mandatory: true,
            dataTestid: 'inputTextProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'IMAGE',
    menu: [
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'imageProperties',
            tooltip: 'Adjust the size of the image here'
          },
          {
            type: 'typeProperties',
            label: 'Type',
            id: 'image',
            tooltip: 'Choose how this image is aligned',
            dataTestid: 'dropdownTypeProperties'
          },
          {
            type: 'bigProperties',
            label: 'Caption',
            id: 'caption',
            tooltip: 'Describe your image here',
            dataTestid: 'inputCaptionProperties'
          }
          // {
          //   type: 'summaryProperties',
          //   dataTestid: 'chkIncludeInSummary'
          // },
        ]
      }
    ]
  },
  {
    type: 'LINK',
    menu: [
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Title',
            id: 'title',
            tooltip: 'What you type here will be shown as link on the Workpage',
            dataTestid: 'inputTitleProperties'
          },
          {
            type: 'bigProperties',
            label: 'URL Link',
            id: 'url',
            tooltip: `The address (URL) of a web page or Rekava's calculation. Example : https://rekava.build`,
            mandatory: true,
            dataTestid: 'inputLinkProperties'
          }
        ]
      }
    ]
  },
  {
    type: 'FIELD',
    menu: [
      {
        type: 'subHeader',
        label: 'FIELD',
        subMenu: [
          {
            type: 'typeProperties',
            label: 'Type',
            id: 'field',
            tooltip: 'Change your input type here'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'PROPERTIES',
        subMenu: [
          {
            type: 'smallProperties',
            label: 'Description',
            id: 'description',
            tooltip: 'Describe your input variable here',
            dataTestid: 'inputDescriptionProperties',
            mandatory: true
          },
          {
            type: 'optionPropertiesField',
            id: 'options',
            tooltip:
              'List all the options you want to be shown in this radio button component',
            dataTestid: 'inputOptionProperties'
          }
        ]
      },
      {
        type: 'subHeaderToggle',
        label: 'OTHER',
        subMenu: [
          {
            type: 'bigProperties',
            label: 'Tooltip',
            id: 'tooltip',
            tooltip:
              'Any text written here will be displayed when this component is hovered over in the Logger View',
            dataTestid: 'inputTooltipProperties'
          },
          {
            type: 'bigProperties',
            label: 'Notes',
            id: 'notes',
            tooltip:
              'Write any notes here to help other Composers understand your calculation',
            dataTestid: 'inputNotesProperties'
          }
        ]
      }
    ]
  }
];

export const expandTableDefaultCellStyle = {
  default: 'background-color: #FFFFFF;', // READ ONLY
  header: 'background-color: #BFC9D9;',
  input: 'background-color: #E3F3FF;',
  readonly: 'background-color: #FFFFFF;'
};

export const defaultFormulaTableCellComposer = [
  {
    colIdx: 0,
    rowIdx: 0,
    value: null,
    showToLogger: true,
    readOnly: true,
    isAdded: false,
    isHeader: true
  },
  {
    colIdx: 1,
    rowIdx: 0,
    value: null,
    showToLogger: true,
    readOnly: true,
    isAdded: false,
    isHeader: true
  },
  {
    colIdx: 2,
    rowIdx: 0,
    value: null,
    showToLogger: true,
    readOnly: true,
    isAdded: false,
    isHeader: true
  },
  {
    colIdx: 0,
    rowIdx: 1,
    value: null,
    showToLogger: true,
    readOnly: false,
    isAdded: false,
    isHeader: false
  },
  {
    colIdx: 1,
    rowIdx: 1,
    value: null,
    showToLogger: true,
    readOnly: false,
    isAdded: false,
    isHeader: false
  },
  {
    colIdx: 2,
    rowIdx: 1,
    value: null,
    showToLogger: true,
    readOnly: true,
    isAdded: false,
    isHeader: false
  },
  {
    colIdx: 0,
    rowIdx: 2,
    value: null,
    showToLogger: true,
    readOnly: false,
    isAdded: false,
    isHeader: false
  },
  {
    colIdx: 1,
    rowIdx: 2,
    value: null,
    showToLogger: true,
    readOnly: false,
    isAdded: false,
    isHeader: false
  },
  {
    colIdx: 2,
    rowIdx: 2,
    value: null,
    showToLogger: true,
    readOnly: true,
    isAdded: false,
    isHeader: false
  }
];

// END OF CONSTANT COMPOSER

// START OF BREADCRUMB 3 SUPER PAGE

export const superPageBreadCrumbData = [
  {
    id: 'back',
    name: 'Back to folder'
  },
  {
    id: 'composer',
    name: 'Compose'
  },
  {
    id: 'logger',
    name: 'Log'
  },
  {
    id: 'print',
    name: 'Print'
  }
];

// END OF BREADCRUMB 3 SUPER PAGE
export const tableDataSample = {
  columnId: 'STRING',
  type: 'TABLE',
  properties: {
    description: 'tableA',
    inSummary: 'BOOLEAN',
    variable: 'STRING',
    isAllowedToAdd: 'ROW',
    maxAddedCol: 'INT',
    maxAddedRow: 'INT',
    tooltip: 'STRING',
    notes: 'STRING',
    headers: [
      {
        width: 'DOUBLE'
      }
    ],
    cells: [
      {
        colIdx: 0,
        rowIdx: 0,
        value: '6',
        showToLogger: true,
        readOnly: true,
        isAdded: false
      },
      {
        colIdx: 1,
        rowIdx: 0,
        value: '25',
        showToLogger: true,
        readOnly: true,
        isAdded: false
      },
      {
        colIdx: 0,
        rowIdx: 1,
        value: '2',
        showToLogger: true,
        readOnly: true,
        isAdded: false
      },
      {
        colIdx: 1,
        rowIdx: 1,
        value: '10',
        showToLogger: true,
        readOnly: true,
        isAdded: false
      },
      {
        colIdx: 0,
        rowIdx: 2,
        value: '=SUM(A1:A2)',
        showToLogger: true,
        readOnly: false,
        isAdded: false
      },
      {
        colIdx: 1,
        rowIdx: 2,
        value: '=SUM(B1:B2)',
        showToLogger: true,
        readOnly: false,
        isAdded: false
      },
      {
        colIdx: 0,
        rowIdx: 3,
        value: '',
        showToLogger: false,
        readOnly: false,
        isAdded: false
      },
      {
        colIdx: 1,
        rowIdx: 3,
        value: '',
        showToLogger: false,
        readOnly: false,
        isAdded: false
      },
      {
        colIdx: 0,
        rowIdx: 4,
        value: '',
        showToLogger: false,
        readOnly: false,
        isAdded: false
      },
      {
        colIdx: 1,
        rowIdx: 4,
        value: '',
        showToLogger: false,
        readOnly: false,
        isAdded: false
      }
    ]
  },
  createdAt: '2022-02-09 05:56:19',
  createdBy: {
    id: 'STRING',
    name: 'STRING'
  },
  updatedAt: '2022-02-09 05:56:19',
  updatedBy: {
    id: 'STRING',
    name: 'STRING'
  }
};

export const breadcrumbsDataSample = [
  { id: '1', name: 'Enterprise Calculation' },
  { id: '2', name: 'Geotechnical' },
  { id: '3', name: 'Reports' }
];

export const SEND_REPORT_URL: any = 'https://forms.gle/KHaS7Xs7Zc3Sg4JWA';

export const mapActionTypeToField = {
  ONBLUR_NOTATION: 'notation',
  ONBLUR_VARIABLE: 'variable'
};

export const windowWidth: any = window.innerWidth;

export const formulaNonExpand: Array<string> = ['VLOOKUP', 'HLOOKUP', 'INDEX'];

export const JSPREADSHEET_LICENSE =
  'NjFjYWEyYjVhZTQ5OTM2NzIzZmQ3YWFjNmRmZDQ1ZTcxYWZiMTIwZTQ4Mzc1YTk0YWU4YTVhZjVmMTYwZGQ2ZWU1MmY2MGViOTIxNzdmZjkzZTc1MzA4MDY1NDk4N2MzODBlMDkwMDQ0N2VlNTZlY2U1NjE1ZjNhNzQ2ZTQzZWUsZXlKdVlXMWxJam9pVFdGeWRHbHVJaXdpWkdGMFpTSTZNVFk1T0RFd01qQXdNQ3dpWkc5dFlXbHVJanBiSW1OdlpHVnpZVzVrWW05NExtbHZJaXdpYW5ObWFXUmtiR1V1Ym1WMElpd2ljbVZyWVhaaExtSjFhV3hrSWl3aWJHOWpZV3hvYjNOMElsMHNJbkJzWVc0aU9pSXpNU0lzSW5OamIzQmxJanBiSW5ZM0lpd2lkamdpTENKMk9TSXNJbVp2Y20xMWJHRWlMQ0ptYjNKdGN5SXNJbkpsYm1SbGNpSXNJbkJoY25ObGNpSXNJbWx0Y0c5eWRHVnlJaXdpZG1Gc2FXUmhkR2x2Ym5NaUxDSmpiMjF0Wlc1MGN5SXNJbk5sWVhKamFDSXNJbU5vWVhKMGN5SmRmUT09';

export const drivesBreadcumb: any = [
  {
    id: '',
    name: 'Business Drive'
  }
];

export const businessDrivesItems: businessDrivesItemsI[] = [
  {
    key: 'createCalculation',
    title: 'Create Calculation',
    dataTestid: 'btnCreateCalculation'
  },
  {
    key: 'addFolder',
    title: 'Add Folder',
    dataTestid: 'btnAddFolder'
  }
];

export const paternsubsup = `<?!?(sup|sub)(.!?)[^>]*>`;

export const bannedKey: string[] = [
  'ArrowUp',
  'ArrowDown',
  'ArrowRight',
  'ArrowLeft'
];

export const FREE = 'FREE';
export const PRO = 'PRO';
export const PRO_PLUS = 'PRO_PLUS';
export const BUSINESS = 'BUSINESS';
export const ENTERPRISE = 'ENTERPRISE';
export const BUSINESS_PLUS = 'BUSINESS_PLUS';
export const ACE = 'ACE';

// User Subscription's types
export enum SubscriptionType {
  FREE = 'FREE',
  PRO = 'PRO',
  PRO_PLUS = 'PRO_PLUS',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
  BUSINESS_PLUS = 'BUSINESS_PLUS',
  ACE = 'ACE',
  ACE_ENTERPRISE = 'ACE_ENTERPRISE'
}
// Announcement Modal
export const MODAL_TITLE = 'Announcement';
export const MODAL_CONTENT = `We would like to express our utmost gratitude to all of you for your trust and support in using the Rekava application.\n
We would like to inform you that Rekava will undergo significant changes in our product direction. The initial application functions that provided engineering calculation features will be sunset or discontinued. We understand that these features have been an important part of Rekava, and we appreciate your support throughout our journey.\n
These changes are a step forward for Rekava, as our new product will focus on AI features similar to "ChatGPT for Construction." We will introduce a virtual assistant named Ace, " The Assistant for Construction and Engineering", designed to assist you with your better understanding your construction documents.\n
Starting from July 15th, you will receive notifications in the Rekava app stating that the engineering calculation function will cease operating on August 11th, 2023. We want to inform you that your account will not be deleted on that date. Instead, you will be given the option to be automatically registered to use Ace. However, on that date, any data on your account related to the engineering calculation function will be archived and deleted.\n
We understand that these changes may raise questions. Therefore, please feel free to contact us at hello@rekava.build.\n
Thank you for your understanding and support thus far. We can't wait to welcome you to the new era of Rekava`;
export const MODAL_CONVERT = `Your account is transitioning to Ace, Rekava's advanced AI-powered virtual assistant. Get ready for an exceptional experience!`;
export const BAR_CONTENT =
  'Starting August 11th 2023, the Engineering Calculation feature of Rekava will cease operations, in place of our AI tool for Construction, Ace';
export const ANNOUNCEMENT_DUE_DATE = '2023-08-11';
export const ANNOUNCEMENT_BUTTON_TEXT_DEFAULT = 'See Full Announcement';
export const ANNOUNCEMENT_BUTTON_TEXT_CONVERT = 'Convert to Ace';
export const TEXT_ERROR_MESSAGE = 'There was an error generating a response';
export const TEXT_REFRESH = 'Refresh';
export const TERM_OF_SERVICE_TITLE = 'Terms of Services and Privacy Policy';
export const TERM_OF_SERVICE_FILENAME = `${TERM_OF_SERVICE_TITLE}.pdf`;
export const TERM_OF_SERVICE_SUCCESS_DOWNLOAD = `${TERM_OF_SERVICE_TITLE} successfully downloaded`;
export const LAST_UPDATE_TITLE = 'Last updated:';
export const LAST_UPDATE_DATE = 'August 07, 2023';
export const TEXT_DOWNLOAD = 'Download';
export const TEXT_CHANGE = 'Change';
export const TEXT_UPLOAD = 'Upload';
export const TEXT_REMOVE = 'Remove';
export const TEXT_ACCOUNT_SETTINGS = 'Account Settings';
export const ADD_COMPANY = 'Add Company';
export const HISTORY = 'History';
export const NEW_CHAT = '+ New Chat';
export const TEXT_NO_INTERNET =
  "At this time, there's no connection to the internet";
export const DRAWING_NUMBER = 'Drawing Number';
export const DESCRIPTION = 'Description';
export const CITATION_FIGURE_INFO =
  'Click the open citation link above to see the drawing';
export const TEXT_ACE_GET_STARTED = 'Get Started with Ace';
export const TEXT_ACE_GET_STARTED_DESC =
  'To start using this powerful tool, you can upload your files and get personalised assistance.';
export const TEXT_CONTACT_US = 'Contact Us';
export const TEXT_DEMO = 'Demo';
export const TEXT_HELP = 'Help';
export const TEXT_ACE_ENTERPRISE = 'ACE_ENTERPRISE';
export const HCSS_URL =
  'https://identity.hcssapps.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dhcsscredentials%26redirect_uri%3Dhttps%253A%252F%252Fhcssapps.com%252F%26response_mode%3Dform_post%26response_type%3Dcode%2520id_token%26scope%3Dopenid%2520profile%2520credentials%2520oemgps%2520offline_access%2520ALL%2520billing%2520cred%253Areact%2520cred%253Aread%2520cred%253Awrite%2520cred%253Asudo%2520intelligence%2520setups%2520timecards%253Aread%2520heavyjob%253Aread%26state%3DOpenIdConnect.AuthenticationProperties%253DAuMkTwj4voIlHKBVVwmX_8--EUiLrWjTAcfTTSYOJU4IDS5pBuA4DXF76IRsUo7I9zOmSo1NmMuerRQqShY6svdprWkuP1F04cqDmTgl1pCBkfkQCHTvfgqEmQMyszgexJzA0huO1HE2qtmQ9HwnWWUsCNraF7ctY-phYr-91yc3-vX6%26nonce%3D638332851721724602.NzUwNjU4MTgtYmU4MC00ZTAzLWEzODktZDQxY2NkMzg3YzE5NWE3NWY1M2UtMWJkYS00NjNiLWJlYWEtYWYwNDNmNWMxYmFl%26acr_values%3Dam%253Atel';
export const CHAT_SESSION_TITLE = 'chat session initialization';
export const TEXT_I_AGREE = 'I Agree';
export const TEXT_CLICK_HERE_SEE_LOCATION =
  'Click here to see the specific file location';
