import { lazy } from 'react';
import { CompposerCreate } from '../pages/composer/create.page';

const AccountSetting = lazy(
  () => import('../pages/accountSetting/AccountSettings')
);
const AtomicPage = lazy(() => import('../pages/atomic'));
const BusinessDrives = lazy(() => import('../pages/businessDrives'));
const LoggerPage = lazy(() => import('../pages/loggerRefactor'));
const PrintView = lazy(() => import('../pages/printView'));
const SubscriptionPage = lazy(
  () => import('../pages/subscriptionPage/SubscriptionPage')
);
const accountManager = lazy(
  () => import('../pages/accountManager/accountManager')
);
const billingDetails = lazy(
  () => import('../pages/billingDetails/billingDetails')
);
const community = lazy(() => import('../pages/calculations/community'));
const dashboard = lazy(() => import('../pages/dashboard/'));
const enterprise = lazy(() => import('../pages/calculations/enterprise'));
const helpPage = lazy(() => import('../pages/calculations/faqPage'));
const inputFile = lazy(() => import('../pages/inputFile/inputFile'));
const login = lazy(() => import('../pages/login2'));
const myCalculations = lazy(
  () => import('../pages/calculations/myCalculations')
);
const report = lazy(() => import('../pages/report/report'));
const AcePublic = lazy(() => import('../pages/askAce/AcePublic'));
const AceEnterprise = lazy(() => import('../pages/askAce/AceEnterprise'));
const AceTable = lazy(() => import('../pages/askAce/AceTable'));

const privateRoute = [
  { path: '/dashboard', component: dashboard },
  {
    path: '/calculations/my-calculation/:folderId?',
    component: myCalculations
  },
  { path: '/calculations/community/:folderId?', component: community },
  { path: '/calculations/enterprise', component: enterprise },
  { path: '/calculations/accountManager', component: accountManager },
  { path: '/calculations/billingDetails/:id', component: billingDetails },
  { path: '/input-file/:calculationId', component: inputFile },
  { path: '/report/:calculationId', component: report },
  { path: '/composer/:id/:folderId', component: CompposerCreate },
  // start of Logger Refactoring
  // { path: "/logger/:id", component: Logger },
  // { path: "/logger/:id/:inputFileId", component: Logger },
  { path: '/logger/:calculationId/:inputFileId?', component: LoggerPage },
  // end of Logger Refactoring
  { path: '/formula', component: login },
  { path: '/print-view/:calculationId/:inputFileId/:id', component: PrintView },

  { path: '/atomic-design', component: AtomicPage },
  { path: '/ace/helpPage', component: helpPage },
  { path: '/calculations/accountSettings', component: AccountSetting },
  { path: '/calculations/subscriptionPage', component: SubscriptionPage },
  {
    path: '/calculations/businessDrives/:folderId?',
    component: BusinessDrives
  },
  { path: '/ace/public/:id?', component: AcePublic },
  { path: '/ace/enterprise/:id?', component: AceEnterprise },
  { path: '/ace/citation/:id?', component: AceTable }
];

const routes = [...privateRoute];
export default routes;
