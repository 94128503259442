import { ChatSessionI } from '../../pages/askAce/askAce.type';
import { IAceVotePayload } from '../../service/AceService';
import {
  ActiveCitationI,
  ChatDataT,
  ChatSessionsPageI
} from '../reducers/aceReducers';

export const FETCH_CHAT_HISTORIES = 'FETCH_CHAT_HISTORIES';
export const FETCH_CHAT_SUCCESS = 'FETCH_CHAT_SUCCESS';
export const FETCH_CHAT_FAILED = 'FETCH_CHAT_FAILED';
export const ADD_CHAT_HISTORY = 'ADD_CHAT_ITEM';
export const UPDATE_ACE_ANSWER = 'UPDATE_ACE_ANSWER';
export const UPDATE_CLASSIFICATION = 'UPDATE_CLASSIFICATION';
export const CREATE_CHAT_HISTORY = 'CREATE_CHAT_HISTORY';
export const UPDATE_CHAT_HISTORY = 'UPDATE_CHAT_HISTORY';
export const SET_USER_FEEDBACK = 'SET_USER_FEEDBACK';
export const RESET_CHAT_HISTORIES = 'RESET_CHAT_HISTORIES';
export const ACE_ANSWER_END = 'ACE_ANSWER_END';
export const FETCH_CHAT_SESSIONS = 'FETCH_CHAT_SESSIONS';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_CHAT_SESSIONS_PAGE = 'SET_CHAT_SESSIONS_PAGE';
export const SET_SESSION_ERROR = 'SET_SESSION_ERROR';
export const FETCH_DATA_SOURCE = 'FETCH_DATA_SOURCE';
export const SET_SQL_CITATIONS = 'SET_SQL_CITATIONS';
export const SET_USER_AGENT = 'SET_USER_AGENT';
export const WAITING_FOR_ANSWER = 'WAITING_FOR_ANSWER';
export const REMOVE_LATEST_ITEM = 'REMOVE_LATEST_ITEM';
export const ADD_NEW_SESSION = 'ADD_NEW_SESSION';
export const ACTIVE_CITATION = 'ACTIVE_CITATION';

export interface LoadingActionI {
  type: typeof FETCH_CHAT_HISTORIES;
  isLoading: boolean;
}

export interface FailedActionI {
  type: typeof FETCH_CHAT_FAILED;
  message?: string;
  isLoading?: boolean;
}

export interface SuccessActionI {
  type: typeof FETCH_CHAT_SUCCESS;
  chatHistories: ChatDataT[];
}

export interface AddChatActionI {
  type: typeof ADD_CHAT_HISTORY;
  question: string;
}

export interface UpdateActionI {
  type: typeof UPDATE_ACE_ANSWER;
  answer: string;
}

export interface AddClassificationI {
  type: typeof UPDATE_CLASSIFICATION;
  classification: string;
}

export interface UpsertActionI {
  type: typeof UPDATE_CHAT_HISTORY;
  chatHistory: ChatDataT;
}

export interface SetUserFeedbackI {
  type: typeof SET_USER_FEEDBACK;
  payload: IAceVotePayload;
}

export interface ResetActionI {
  type: typeof RESET_CHAT_HISTORIES;
}

export interface AnswerEndI {
  type: typeof ACE_ANSWER_END;
}

export interface FetchChatSessionsI {
  type: typeof FETCH_CHAT_SESSIONS;
  chatSessions: ChatSessionI;
}

export interface SetSessionIdI {
  type: typeof SET_SESSION_ID;
  sessionId: string | null;
}

export interface SetChatSessionsPageI {
  type: typeof SET_CHAT_SESSIONS_PAGE;
  data: ChatSessionsPageI;
}

export interface SetSessionErrorI {
  type: typeof SET_SESSION_ERROR;
  payload: boolean;
}

export interface FetchingActionI {
  type: typeof FETCH_DATA_SOURCE;
  isFetching: boolean;
}

export interface SetSqlCitationsI {
  type: typeof SET_SQL_CITATIONS;
  data: ChatDataT;
}

export interface SetUserAgentI {
  type: typeof SET_USER_AGENT;
  payload: boolean;
}

export interface WaitingForAnswerI {
  type: typeof WAITING_FOR_ANSWER;
  payload: boolean;
}

export interface RemoveLatestItemI {
  type: typeof REMOVE_LATEST_ITEM;
}

export interface AddNewSessionI {
  type: typeof ADD_NEW_SESSION;
  chatSession: ChatSessionI;
}

export interface SetActiveCitationI {
  type: typeof ACTIVE_CITATION;
  payload: ActiveCitationI | null;
}
