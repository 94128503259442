// debounce.ts
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number
) => {
  let timeout: NodeJS.Timeout | null = null;

  // The debounced function
  const debounced = (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };

  // The cancel method to clear the timeout
  debounced.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  // Return the debounced function along with cancel method
  return debounced;
};

// isString.ts
export const isString = (value: any): boolean => {
  return typeof value === 'string';
};

// isNan.ts
export const isNaN = (value: any): boolean => {
  return typeof value === 'number' && isNaN(value);
};

const superscriptMap: { [key: string]: string } = {
  '0': '⁰',
  '1': '¹',
  '2': '²',
  '3': '³',
  '4': '⁴',
  '5': '⁵',
  '6': '⁶',
  '7': '⁷',
  '8': '⁸',
  '9': '⁹',
  '-': '⁻'
};

const subscriptMap: { [key: string]: string } = {
  '0': '₀',
  '1': '₁',
  '2': '₂',
  '3': '₃',
  '4': '₄',
  '5': '₅',
  '6': '₆',
  '7': '₇',
  '8': '₈',
  '9': '₉',
  a: 'ₐ',
  e: 'ₑ',
  h: 'ₕ',
  i: 'ᵢ',
  j: 'ⱼ',
  k: 'ₖ',
  l: 'ₗ',
  m: 'ₘ',
  n: 'ₙ',
  o: 'ₒ',
  p: 'ₚ',
  r: 'ᵣ',
  s: 'ₛ',
  t: 'ₜ',
  u: 'ᵤ',
  v: 'ᵥ',
  x: 'ₓ'
};

export function latexToText(latex: string): string {
  let result = latex;
  result = result.replace(/\\text\{([^}]+)\}/g, '$1');
  result = result.replace(/\\times/g, '×');
  result = result.replace(/\\left\(/g, '').replace(/\\right\)/g, '');
  result = result.replace(/\\([A-Za-z]+)\^{(-?\d+)}/g, (match, p1, p2) => {
    return (
      p1 +
      p2
        .split('')
        .map((char: any) => superscriptMap[char])
        .join('')
    );
  });
  result = result.replace(/\\frac\{([^\}]+)\}\{([^\}]+)\}/g, '($1 / $2)');
  return result;
}

const convertSubscriptMap = (str: string): string => {
  // Replace _0 to _9 with subscript characters
  str = str.replace(/_([0-9a-z])/g, (_, digit) => subscriptMap[digit]);
  return str;
};

const convertSubscript = (str: string): string => {
  // Subscript conversion
  str = str.replace(/_([0-9a-z])/g, (_, digit) => {
    let unicodeValue;
    if (/[0-9]/.test(digit)) {
      unicodeValue = 0x2080 + parseInt(digit); // Subscript numbers
    } else if (/[a-z]/.test(digit)) {
      unicodeValue = 0x2090 + (digit.charCodeAt(0) - 'a'.charCodeAt(0)); // Subscript letters
    } else {
      return _;
    }
    const utf8Bytes =
      Buffer.from(String.fromCharCode(unicodeValue), 'utf8') // Convert to UTF-8 bytes
        .toString('hex') // Convert to hex
        .match(/.{1,2}/g) // Split into byte pairs
        ?.map(byte => `\\x${byte.toUpperCase()}`) // Format as \xHH
        .join('') || ''; // Join bytes back

    return utf8Bytes;
  });
  return str;
};

export const decodeUnicodeEscapeSequences = (
  str: string,
  hex?: boolean
): string => {
  if (!hex) {
    return convertSubscriptMap(str);
  }
  str = convertSubscript(str);
  // Formula
  str = str.replace(/\$\$(.*?)\$\$/g, (_, value) => {
    value = value.replace(/<br>/g, '');
    value = value.replace(/\\\\\\\\/g, '\\');
    value = value.replace(/\\\\/g, '\\');
    return `$$${value}$$`; // Modify the value (e.g., uppercase)
  });
  // Decode Unicode escape sequences (\uXXXX)
  str = str.replace(/\\u([0-9a-fA-F]{4})/g, (_, hex) =>
    String.fromCodePoint(parseInt(hex, 16))
  );
  // Convert \xHH sequences into raw bytes
  const bytes = str.replace(/\\x([0-9a-fA-F]{2})/g, (_, hex) =>
    String.fromCharCode(parseInt(hex, 16))
  );
  // Convert the string into a Uint8Array
  const byteArray = Array.from(bytes, c => c.charCodeAt(0));
  // Use TextDecoder to properly interpret UTF-8 encoded bytes
  const txt = new TextDecoder('utf-8').decode(new Uint8Array(byteArray));
  // Remove all remaining backslashes
  return txt.replace(/\\\\/g, '\\');
};

export const toTitleCase = (str: string, role?: boolean) => {
  let txt: string = str;
  if (role) {
    if (txt === 'COMPANY_ADMIN') {
      txt = 'ADMIN';
    } else {
      txt = 'USER';
    }
  }
  return txt
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, char => char.toUpperCase());
};
