import { FC, useEffect, useState } from 'react';
import { customRole } from '../../../utils/constant';
import { getIsConsents, getUserProfile } from '../../../utils/storage';
import NavBar from '../../molecules/navbar';
import { Body } from './elements';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store';
import { useLocation } from 'react-router-dom';
import {
  reducerModifierDataBodyOfCalculationActionComposer,
  reducerModifierDataPropertiesActionComposer
} from '../../../store/composer/composerPage/composerActions';
import { getDataBodyOfCalculationComposer } from '../../../utils/reduxOutside';
import { inputSwitchActionMenuComposer } from '../../../store/composer/composerMenu/composerMenuActions';
import { useApi } from '../../../utils/useAPI';
import Service from '../../../service/generic/generic';

const userService = new Service('user');

const Layout: FC = ({ children }) => {
  const location = useLocation();
  const userRole = getUserProfile();
  const dispatch = useDispatch();

  const [isProfile, setProfile] = useState({
    organization: { name: '' },
    name: '',
    profilePictureUrl: ''
  });

  const { dataProperties } = useSelector(
    (state: ReduxState) => state.composer,
    shallowEqual
  );

  const { userProfile } = useSelector(
    (state: ReduxState) => state.users,
    shallowEqual
  );

  const { profilePictureUrl, organization, personalUserCompany, name } =
    userProfile.name ? userProfile : isProfile;

  useEffect(() => {
    const userConsent: Record<string, unknown> = getIsConsents();
    const isConsent = userConsent.isConsent;
    const getProfile = async () => {
      const resp: any = await useApi(
        `user_profile`,
        () => userService.get('profile'),
        true
      );
      if (resp?.data) {
        setProfile({
          profilePictureUrl: resp.data.profilePictureUrl ?? '',
          organization: resp.data.organization ?? { name: '' },
          name: resp.data.name ?? ''
        });
      }
    };
    isConsent && getProfile();
  }, []);

  useEffect(() => {
    const DataBodyOfCalculationComposer = getDataBodyOfCalculationComposer();
    if (
      !location.pathname.includes('composer') &&
      DataBodyOfCalculationComposer.length > 0
    ) {
      dispatch(inputSwitchActionMenuComposer('reset'));
      dispatch(reducerModifierDataBodyOfCalculationActionComposer([]));
      dispatch(
        reducerModifierDataPropertiesActionComposer({
          ...dataProperties,
          showProperties: false
        })
      );
    }
  }, [location]);

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <NavBar
        userName={name}
        userOrganization={
          personalUserCompany ? personalUserCompany : organization.name
        }
        userRole={customRole[String(userRole)]}
        {...{ currentPict: profilePictureUrl }}
      />
      <Body>{children}</Body>
    </div>
  );
};

export default Layout;
